import React, { createContext, useState, useContext, ReactNode } from 'react';
import { CSP_KEY, LOGIN_TYPE, PING_ACCESS_TOKEN, USER_PERSONA } from '../Utility/constants';
import LocalStorageCacheService from '../Utility/LocalStorageCacheService';

interface ApplicationState {
  loginType: string | null;
  persona: string;
  isCsp: boolean;
  pingAccessToken: string | null;
  registrationType: string; //equivalent to loginType
  tierLevel: string; //sub category of persona for HVC
}

interface ApplicationContextType {
  applicationState: ApplicationState;
  setApplicationState: React.Dispatch<React.SetStateAction<ApplicationState>>;
  onUpdateState: (key: string, value: any) => void;
}

interface Props {
  children: ReactNode;
}

export const ApplicationContext = createContext<ApplicationContextType | null>(null);

export const ApplicationProvider: React.FC<Props> = ({ children }) => {
  const [applicationState, setApplicationState] = useState<ApplicationState>({
    loginType: LocalStorageCacheService.get(LOGIN_TYPE) ? LocalStorageCacheService.get(LOGIN_TYPE) : null,
    persona: LocalStorageCacheService.get(USER_PERSONA) ? LocalStorageCacheService.get(USER_PERSONA) : null,
    isCsp: LocalStorageCacheService.get(CSP_KEY) === 'true' ? true : false,
    pingAccessToken: LocalStorageCacheService.get(PING_ACCESS_TOKEN)
      ? LocalStorageCacheService.get(PING_ACCESS_TOKEN)
      : '',
    registrationType: LocalStorageCacheService.get(LOGIN_TYPE) ? LocalStorageCacheService.get(LOGIN_TYPE) : null,
    tierLevel: '',
  });

  const onUpdateState = (key: string, value: any) => {
    setApplicationState((prevState: any) => {
      return { ...prevState, [key]: value };
    });
  };

  const value: ApplicationContextType = {
    applicationState,
    setApplicationState,
    onUpdateState,
  };

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};

export const useApplicationContext = (): ApplicationContextType => useContext(ApplicationContext)!;
