import fetch from 'isomorphic-fetch';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { HRC_TOKEN, HVC_TOKEN } from '../Utility/constants';
import { onError } from '@apollo/client/link/error';
import LocalStorageCacheService from '../Utility/LocalStorageCacheService';
import { v4 as uuidv4 } from 'uuid';

// GraphQL (HVC) Server Link
const hvc_link = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  headers: {
    'x-correlation-id': `hvc-${uuidv4()}`
  },
  fetch,
});

const hvc_authLink = setContext((_, { headers }) => {
  const token = LocalStorageCacheService.get(HVC_TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const hvc = hvc_authLink.concat(hvc_link);

// REST API (HRC) endpoint Link
const hrc_link = new RestLink({
  uri: process.env.REST_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-correlation-id': `hrc-${uuidv4()}`
  },
});

const hrc_authLink = setContext((_, { headers }) => {
  const token = LocalStorageCacheService.get(HRC_TOKEN) ? 
    LocalStorageCacheService.get(HRC_TOKEN) : LocalStorageCacheService.get(HVC_TOKEN);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const hrc = hrc_authLink.concat(hrc_link);

// Jahia REST API endpoint
const jahia = new RestLink({
  uri: process.env.JAHIA_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const hyatt = ApolloLink.split(
  (operation) => operation?.getContext().client === 'rest',
  hrc, // Apollo will send to this if client is "rest"
  hvc, // Otherwise, will send to this
);

const withJahia = ApolloLink.split(
  (operation) => operation?.getContext().client === 'jahia',
  jahia, // Apollo will send to this if client is "jahia"
  hyatt, // Otherwise, will send to this
);

const logoutLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) console.error("401 Error on Apollo: ", networkError);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink?.concat(withJahia),
});

export default client;
