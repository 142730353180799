import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { ApplicationProvider } from './src/context/application-context';
// import { JahiaProvider } from './src/context/jahia-context';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

declare global {
  interface Window {
    _satellite: any;
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <React.StrictMode>
      <ApplicationProvider>{element}</ApplicationProvider>
    </React.StrictMode>
  );
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  if (typeof window._satellite !== 'undefined') {
    const routeChangeEventName = 'gatsbyRouteChange';
    window._satellite.track(routeChangeEventName);
  }
};
