class LocalStorageCacheService {
  private static date = new Date();
  private static cacheKeyPrefix = 'hype-';
  private static defaultExpiration = 30; // minutes

  static get(cachekey: string): any {
    try {
      this.processExpiredKeys();
      var record = JSON.parse(localStorage.getItem(this.cacheKeyPrefix + cachekey) as string);
      return record?.value ? (typeof record?.value === 'string' ? record?.value : JSON.parse(record?.value)) : false;
    } catch (e) {
      console.error(e, 'Could not get key.');
    }
  }

  static set(cachekey: string, obj: any, expirationInMinutes?: number): void {
    try {
      this.processExpiredKeys();
      expirationInMinutes = expirationInMinutes
        ? this.convertMinutesToEpoch(expirationInMinutes)
        : this.convertMinutesToEpoch(this.defaultExpiration);
      var record = {
        value: typeof obj === 'string' ? obj : JSON.stringify(obj),
        expiration: expirationInMinutes,
      };
      localStorage.setItem(this.cacheKeyPrefix + cachekey, JSON.stringify(record)); // Return JSON data
    } catch (e) {
      console.error(e, 'Could not set key.');
    }
  }

  static delete(cachekey: string): void {
    try {
      this.processExpiredKeys();
      localStorage.removeItem(this.cacheKeyPrefix + cachekey);
    } catch (e) {
      console.error(e, 'Could not delete key.');
    }
  }
  static deleteSessionKey(cachekey: string): void {
    try {
      this.processExpiredKeys();
      sessionStorage.removeItem(cachekey);
    } catch (e) {
      console.error(e, 'Could not delete key.');
    }
  }
  static flush(): void {
    for (var key in localStorage) {
      try {
        if (key.indexOf(this.cacheKeyPrefix) !== -1) {
          localStorage.removeItem(key);
        }
      } catch (e) {
        console.error(e, 'Could not flush keys.');
      }
    }
  }
  static flushWithout(keys: string[]): void {
    for (var key in localStorage) {
      try {
        if (key.indexOf(this.cacheKeyPrefix) !== -1) {
          if (!keys.includes(key)) localStorage.removeItem(key);
        }
      } catch (e) {
        console.error(e, 'Could not flush keys.');
      }
    }
  }
  private static processExpiredKeys(): void {
    for (var key in localStorage) {
      try {
        if (key.indexOf(this.cacheKeyPrefix) !== -1) {
          var record = JSON.parse(localStorage.getItem(key) as string);
          if (Date.now() > record.expiration) {
            localStorage.removeItem(key);
          }
        }
      } catch (e) {
        console.error(e, 'Could not clear keys.');
      }
    }
  }

  private static convertMinutesToEpoch(minutes: number): number {
    let date = new Date();
    return date.setMinutes(date.getMinutes() + minutes);
  }
}

export default LocalStorageCacheService;
